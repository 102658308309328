var CB = CB || {};

CB.spinnerId = '#spinner';
CB.$spinner = $(CB.spinnerId);
CB.spinnerTrigger = 'a.spinner-trigger';
CB.openMobileMenuTrigger = '.mobile-menu-trigger';
CB.closeMobileMenuTrigger = '.mobile-nav-close';
CB.closePopupTrigger = '.close-popup-trigger';
CB.popupClass = '.cb-popup';
CB.aboutUsId = '#about-us-popup';
CB.aboutUsTrigger = '.about-us-trigger';
CB.mobileMenuId = '#mobile-nav';
CB.$mobileMenu = $(CB.mobileMenuId);
CB.bbcWrapperId = '#bbc-wrapper';
CB.bbcId = '#blue-circle';
CB.menuVisible = false;

CB.init = () => {
  $(document).ready(() => {
    // makes nav menu clicks to show loader
    $(CB.spinnerTrigger).on('click', event => {
      CB.showSpinner();
    });

    $(CB.openMobileMenuTrigger).on('click', event => {
      CB.showMobileMenus();
    });

    $(CB.closeMobileMenuTrigger).on('click', event => {
      CB.hideMobileMenus();
    });

    $(CB.closePopupTrigger).on('click', event => {
      CB.hidePopup();
    });

    $(CB.aboutUsTrigger).on('click', event => {
      event.preventDefault();
      CB.showAboutUs();
    });
  });

  $(window).on('resize', CB.onResize);

  $(window).on('load', () => {
    CB.hideSpinner();
    $('#blue-circle').removeClass('initial');

    // fix scroll position because of BBC animation
    setTimeout(() => {
      $(window).scrollTop(0).scrollLeft(0);
      CB.fixPositioning();
    }, 100);
  });
};

CB.showSpinner = () => {
  anime({
    targets: CB.spinnerId,
    opacity: 1,
    easing: 'easeInOutExpo',
    begin: anim => {
      CB.$spinner.show();
    },
  });
};

CB.hideSpinner = () => {
  anime({
    targets: CB.spinnerId,
    opacity: 0,
    easing: 'easeInOutExpo',
    complete: anim => {
      CB.$spinner.hide();
    },
  });
};

CB.showMobileMenus = () => {
  anime({
    targets: CB.mobileMenuId,
    opacity: 1,
    easing: 'easeInOutExpo',
    begin: anim => {
      CB.menuVisible = true;
      CB.lockBody();
      CB.$mobileMenu.show();
    },
  });
};

CB.hideMobileMenus = callback => {
  if (CB.menuVisible) {
    anime({
      targets: CB.mobileMenuId,
      opacity: 0,
      easing: 'easeInOutExpo',
      complete: anim => {
        CB.menuVisible = false;
        CB.releaseBody();
        CB.$mobileMenu.hide();
        if (typeof callback === 'function') {
          callback();
        }
      },
    });
  } else {
    if (typeof callback === 'function') {
      callback();
    }
  }
};

CB.showAboutUs = () => {
  const $aboutPopup = $(CB.aboutUsId);
  CB.hideMobileMenus(() => {
    CB.lockBody();

    anime({
      targets: CB.aboutUsId,
      opacity: 1,
      easing: 'easeInOutExpo',
      begin: anim => {
        $aboutPopup.show();
      },
    });
  });
};

CB.hidePopup = () => {
  const $popup = $(CB.popupClass);
  anime({
    targets: CB.popupClass,
    opacity: 0,
    easing: 'easeInOutExpo',
    complete: anim => {
      CB.releaseBody();
      $popup.hide();
    },
  });
};

CB.lockBody = () => {
  $('body').addClass('locked');
};

CB.releaseBody = () => {
  $('body').removeClass('locked');
};

CB.onResize = () => {
  setTimeout(() => {
    if ($(window).width() > 991) {
      CB.hideMobileMenus();
    }

    CB.fixPositioning();
  }, 500);
};

CB.fixPositioning = () => {
  // makes BBC wrapper got the same height as child
  $(CB.bbcWrapperId).height($(CB.bbcId).height());
};

CB.init();
